import App, { AppProps, AppContext } from 'next/app';
import Router from 'next/router';
import React from 'react';
import { CookiesProvider, Cookies } from 'react-cookie';
import * as Sentry from '@sentry/browser';
import { observer } from 'mobx-react';
import { ReactQueryDevtools } from 'react-query-devtools';
import { ReactQueryCacheProvider, QueryCache } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import { isEmpty } from 'lodash';
import { appWithTranslation } from '../components/i18n';
import { SetToken } from '../services/adminCenterEndPoint';
import { GlobalStyle } from '../components/Theme';
import { AgentStoreProvider } from '../components/useStores/useAgentStore';
import { Permissions } from '../components/types';
import { IsServerOnline } from '../services/login';


Sentry.init({
  dsn: 'https://42da13cf38bc4312bf2874340ac2ae69@o215953.ingest.sentry.io/5557455',
  environment: process.env.NODE_ENV,
  maxBreadcrumbs: 50,
});

const queryCache = new QueryCache();

const accessFreeSites = ['/', '/maintenance'];

interface CustomApp extends AppProps {
  cookie: Cookies;
}

class AdminCenter extends App<CustomApp> {
  static async getInitialProps({ Component, ctx }: AppContext) {
    const { req, res, pathname, query } = ctx;
    const cookie = req ? req.headers.cookie : null;
    let token = null;
    let permissions = {};
    let pageProps = {};
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    if (cookie) {
      const cookieContainer = new Cookies(cookie);
      token = cookieContainer.get('Token');
      permissions = cookieContainer.get('Perms');
    } else if (typeof window !== 'undefined') {
      permissions =
        JSON.parse(localStorage?.getItem('userAgentStore') || '')
          ?.permissions || {};
    }
    // Check Backend, if response returns status 900 redirect to maintenance
    // if (pathname !== '/maintenance' && res) {
    //   IsServerOnline()
    //     .then(rres => {
    //       if (!rres) {
    //         // HIER MUSS REDIRECT REIN der hier geht nicht :(
    //         // res.write(302, { Location: '/maintenance' });
    //         // res.end();
    //       }
    //     })
    //     .catch(err => console.log(err));
    // }
    try {
      if (!token && !accessFreeSites?.includes(pathname) && res) {
        res.writeHead(302, { Location: '/' });
        res.end();
      } else if (!this.hasPermission(pathname, permissions, query)) {
        console.log('no permissironi');
        if (res) {
          res.writeHead(302, { Location: '/dashboard' });
          res.end();
        } else {
          Router.push('/dashboard');
        }
      }
    } catch (error) {
      console.warn('lol', error);
    }
    return {
      pageProps,
      cookie
    };
  }

  constructor(props: CustomApp) {
    super(props);
    const { cookie } = props;
    const token = new Cookies(cookie).get('Token');
    if (token) {
      SetToken(token);
    }
  }

  static hasPermission(
    route: string,
    permissions: Permissions,
    queryy: { type?: string } = { type: '' }
  ): boolean {
    let required: string[] = [];
    switch (route) {
      case '/':
      case '/dashboard':
      case '/maintenance':
      case '/agent':
      case '/404':
      case '/_Err':
        return true;
      case '/documents':
        required = ['can_admin_lock'];
        break;
      case '/agencies':
        required = ['can_list_agency'];
        break;
      case '/teams':
        required = ['can_list_team'];
        break;
      case '/agents':
        required = ['can_list_agent'];
        break;
      case '/restroom':
        required = ['can_list_quality_controle_restroom'];
        break;
      case '/frozendialogs':
        required = ['can_list_quality_controle_freezed_dialogs'];
        break;
      case '/livedialogs':
        required = ['can_list_quality_controle_live_dialogs'];
        break;
      case '/ticketcenter':
        required = ['can_list_quality_controle_ticket_center'];
        break;
      case '/invoice':
        required = ['cyka'];
        break;
      case '/statistics': {
        if (isEmpty(queryy)) {
          required = [
            'can_list_statistics_time_management',
            'can_list_statistics_effectiveness',
            'can_list_statistics_in_out',
            'can_list_statistics_income'
          ];
        }
        if (queryy?.type === 'timeManagement') {
          required = ['can_list_statistics_time_management'];
        }
        if (queryy?.type === 'efficiency') {
          required = ['can_list_statistics_effectiveness'];
        }
        if (queryy?.type === 'inOut') {
          required = ['can_list_statistics_in_out'];
        }
        if (queryy?.type === 'earnings') {
          required = ['can_list_statistics_income'];
        }
        if (queryy?.type === 'workingTimes') {
          required = ['can_show_agent'];
        }
        break;
      }
      case '/dialoghistory':
        required = ['can_list_quality_controle_live_dialogs'];
        break;
      case '/reported':
        required = ['can_list_quality_controle_live_dialogs']
        break;
      default:
        break;
    }
    return required.map(ele => ele in permissions).some(ele => ele === true);
  }


  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps, cookie } = this.props;
    const cookies = new Cookies(cookie);

    return (
      <ReactQueryCacheProvider queryCache={queryCache}>
        <CookiesProvider cookies={cookies}>
          <AgentStoreProvider>
            <GlobalStyle />
            <Hydrate state={pageProps.dehydratedState}>
              <Component {...pageProps} />
            </Hydrate>
            <ReactQueryDevtools initialIsOpen={false} />
          </AgentStoreProvider>
        </CookiesProvider>
      </ReactQueryCacheProvider>
    );
  }
}

export default appWithTranslation(AdminCenter);
